import React from "react";
import SEO from "../components/SEO";
import ImpressumLayout from "../components/layouts/ImpressumLayout";

const ImpressumPage = () => {
  return (
    <ImpressumLayout seoComponent={<SEO title="Impressum" />} title="Impressum">
      <h2>Verantwortlich für den Inhalt</h2>
      <p>
        BRUGG Lifting AG
        <br />
        Wydenstrasse 36
        <br />
        CH-5242 Birr
        <br />
        <a href="tel:+41564644242">+41 56 464 42 42</a>
        <br />
        <a href="mailto:info.lifting@brugg.com">info.lifting@brugg.com</a>
      </p>

      <h2>Web- und Mobile App Entwicklung</h2>
      <p>
        Florian Gyger Software
        <br />
        Bachmattstrasse 15
        <br />
        CH-5210 Windisch
        <br />
        <a href="https://floriangyger.ch" target="_blank" rel="noopener">
          www.floriangyger.ch
        </a>
      </p>

      <h2>Rechtliche Hinweise</h2>

      <h3>Haftungsausschluss</h3>
      <p>
        Der Inhalt dieser Website dient ausschliesslich Informationszwecken und
        kann jederzeit und ohne vorherige Ankündigung geändert werden. BRUGG
        übernimmt keine Gewährleistung für die Richtigkeit, Vollständigkeit und
        Aktualität der auf dieser Website publizierten Informationen. BRUGG
        schliesst jede Haftung für Verluste bzw. Schäden irgendwelcher Art aus,
        die sich aus der Benützung der bzw. des Zugriffs auf diese Website
        ergeben sollten.
      </p>

      <h3>Kein Angebot</h3>
      <p>
        Die auf dieser Website publizierten Informationen stellen weder eine
        Empfehlung noch ein Angebot oder eine Aufforderung zum Abschluss
        irgendeines Rechtsgeschäftes dar.
      </p>

      <h3>Verknüpfte Websites (Links)</h3>
      <p>
        BRUGG übernimmt keinerlei Verantwortung für die Richtigkeit,
        Vollständigkeit und Rechtmässigkeit des Inhalts von Websites Dritter,
        die über diese Website zugänglich sind.
      </p>

      <h3>Benutzung der Websites</h3>
      <p>
        Der gesamte Inhalt dieser Website ist urheberrechtlich geschützt.
        Sämtliche Urheberrechte gehören BRUGG, falls nicht anders angegeben. Das
        Benutzen dieser Website für andere als persönliche Zwecke ist ohne
        vorherige schriftliche Zustimmung der BRUGG untersagt.
      </p>

      <h3>Lokale gesetzliche Einschränkungen</h3>
      <p>
        Diese Website ist nicht für Personen bestimmt, die einer Rechtsordnung
        unterstehen, die die Publikation bzw. den Zugang zu dieser Website
        (aufgrund der Nationalität der betreffenden Person, ihres Wohnsitzes
        oder aus anderen Gründen) verbietet. Personen, auf die solche
        Einschränkungen zutreffen, ist der Zugriff auf diese Website untersagt.
      </p>

      <h3>Cookies</h3>
      <p>
        Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
        richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
        Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
        sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem
        Rechner abgelegt werden und die Ihr Browser speichert. Die meisten der
        von uns verwendeten Cookies sind so genannte „Session-Cookies“. Sie
        werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies
        bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Diese
        Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch
        wiederzuerkennen. Sie können Ihren Browser so einstellen, dass Sie über
        das Setzen von Cookies informiert werden und Cookies nur im Einzelfall
        erlauben, die Annahme von Cookies für bestimmte Fälle oder generell
        ausschließen sowie das automatische Löschen der Cookies beim Schließen
        des Browser aktivieren. Bei der Deaktivierung von Cookies kann die
        Funktionalität dieser Website eingeschränkt sein.
      </p>

      <h3>Copyright</h3>
      <p>
        Der Inhalt dieser Website darf für jegliche Verwendungszwecke nur mit
        schriftlicher Einwilligung von Brugg Lifting AG, CH-5200 Brugg,
        verwendet werden.
      </p>
    </ImpressumLayout>
  );
};

export default ImpressumPage;
